import React, { useEffect, useState, useCallback } from 'react';
import './schoolEmails.scss';
import SchoolSideBar from '../../components/SchoolSideBar/SchoolSideBar';
import { useParams } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { buildForm, formatDate, getApiBaseUrl } from '../../helpers/globalFunctions';
import SortTableHeader from '../../components/SortTableHeader/SortTableHeader';
import TablePageSizeOptions from '../../components/TablePageSizeOptions/TablePageSizeOptions';
import SimplePagination from '../../components/Pagination/SimplePagination';
import SelectColumnsModal from '../../modals/SelectColumnsModal/SelectColumnsModal';
import DownloadingModal from '../../modals/DownloadingModal/DownloadingModal';
import ResendEmailModal from '../../modals/ResendEmailModal/resendEmailModal';

const SchoolEmails = ({ displayModal, closeModal, setModalContent, setShowSchoolName }) => {
    const { event_id } = useParams();
    const { company_id } = useParams();

    const { getUserJwt } = useUser()
    const [initialSearch, setInitialSearch] = useState(true)
    const [allowNoResults, setAllowNoResults] = useState(false)
    const [hasFilter, setHasFilter] = useState(false)

    const [pageIndex, setPageIndex] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [pageSize, setPageSize] = useState(10)

    const [records, setRecords] = useState([])
    const [showRecords, setShowRecords] = useState(false)

    const [emailTypes, setEmailTypes] = useState([])

    const [selectedEmailType, setSelectedEmailType] = useState('')
    const [selectedEmail, setSelectedEmail] = useState('')
    const [selectedOrderId, setSelectedOrderId] = useState('')
    const [selectedStudentId, setSelectedStudentId] = useState('')
    const [selectedStartDate, setSelectedStartDate] = useState('')
    const [selectedEndDate, setSelectedEndDate] = useState('')
    const [sortBy, setSortBy] = useState('sent_date')
    const [displaySortBy, setDisplaySortBy] = useState('sent_date')
    const [orderBy, setOrderBy] = useState('DESC')
    const [displayOrderBy, setDisplayOrderBy] = useState('DESC')

    const [allColumns, setAllColumns] = useState({
        email_type: { id: 'email_type', name: 'Email Type', display: true },
        name: { id: 'name', name: 'Name', display: true },
        email: { id: 'email', name: 'Email', display: true },
        status: { id: 'status', name: 'Status', display: true },
        sent_date: { id: 'sent_date', name: 'Sent Date', display: true },
        opened: { id: 'opened', name: 'Opened', display: true },
        click_through: { id: 'click_through', name: 'Click Through', display: true },
    });

    const [downloadingInProgress, setDownloadingInProgress] = useState(false)
    const [downloadingPageIndex, setDownloadingPageIndex] = useState(null)
    const [csvData, setCsvData] = useState(null)
    const [csvDataHeaders, setCsvDataHeaders] = useState(null)

    const [elementToFocus, setElementToFocus] = useState('')

    useEffect(() => {
        clickSearchButton()
        setHasFilter(false)
    }, [])

    useEffect(() => {
        if (!hasFilter) {
            clickSearchButton(true)
        }
    }, [hasFilter])

    useEffect(() => {
        clickSearchButton()
    }, [pageSize, pageIndex])

    useEffect(() => {
        clickSearchButton()
    }, [displaySortBy, displayOrderBy])

    useEffect(() => {
        setShowSchoolName(false)
        if (!initialSearch) {
            setHasFilter(true)
        }
    }, [selectedEmailType, selectedEmail, selectedStudentId, selectedOrderId, selectedStartDate, selectedEndDate, sortBy, orderBy, initialSearch, setShowSchoolName])

    const clickSortColumn = (col) => {
        if (col === sortBy) {
            let updated_order_by = orderBy === 'ASC' ? 'DESC' : 'ASC'
            setOrderBy(updated_order_by, setDisplayOrderBy(updated_order_by))
        } else {
            setSortBy(col, setDisplaySortBy(col))
        }
    }

    const handleEnterPressed = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.click()
            document.activeElement.blur()
            setTimeout(() => { event.target.focus() }, 500)
        }
    }

    const modalBlur = (event, id) => {
        if (event.relatedTarget) {
            if (event.relatedTarget.getAttribute('data-modal') !== 'true') {
                event.preventDefault();
                document.getElementById(id).focus();
            }
        }
    }

    const refocusElement = useCallback((id, index) => {
        setTimeout(async () => {
            let element = document.getElementById(id);
            if (index < 10) {
                if (element && element !== document.activeElement) {
                    element.focus();
                } else {
                    setTimeout(() => { refocusElement(id, index + 1) }, 50)
                }
            }
        }, 250);
    }, [])

    const clickSearchButton = async (clear = false) => {
        setSortBy(displaySortBy, setOrderBy(displayOrderBy))

        let body_data;

        if (clear) {
            body_data = {
                event_id: event_id,
                company_id: company_id,
                email_type: '',
                coordinator_name: '',
                coordinator_id: '',
                email: '',
                student_id: '',
                order_id: '',
                dynamics_id: '',
                school_name: '',
                start_send_date: '',
                end_send_date: '',
                sort_by: sortBy,
                order_by: orderBy,
                page_size: pageSize !== 'All' ? parseInt(pageSize) : 'All',
                page_index: pageIndex,
            }
        } else {
            body_data = {
                event_id: event_id,
                company_id: company_id,
                email_type: selectedEmailType,
                coordinator_name: '',
                coordinator_id: '',
                email: selectedEmail,
                student_id: selectedStudentId,
                order_id: selectedOrderId,
                dynamics_id: '',
                school_name: '',
                start_send_date: selectedStartDate,
                end_send_date: selectedEndDate,
                sort_by: sortBy,
                order_by: orderBy,
                page_size: pageSize !== 'All' ? parseInt(pageSize) : 'All',
                page_index: pageIndex,
            }
        }

        var formBody = buildForm(body_data)
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
        let jwt = await getUserJwt();
        var bearer = "Bearer " + jwt;
        headers.append("Authorization", bearer);

        try{ 
            const response = await fetch(`${getApiBaseUrl()}/email/searchAllEmails`, {
                method: 'post',
                headers: headers,
                body: formBody,
            });

            const responseJson = await response.json();

            if (responseJson.data) {
                if (initialSearch) {
                    setInitialSearch(false)
                }

                setRecords(responseJson.data.emails)
                setEmailTypes(responseJson.data.email_types)
                setPageCount(responseJson.data.pages_count)
                setAllowNoResults(true)
            }
        } catch (error) {
            console.error(error);
        };
    }

    const clearSearchFilters = () => {
        setSelectedEmailType('')
        setSelectedEmail("")
        setSelectedStudentId("")
        setSelectedOrderId("")
        setSelectedStartDate('')
        setSelectedEndDate('')
        setSortBy('sent_date')
        setDisplaySortBy('sent_date')
        setOrderBy('DESC')
        setDisplayOrderBy('DESC')
    }

    const handleSearch = async () => {
        await clickSearchButton();
        setShowRecords(true);
    }

    const clearSearch = async () => {
        await clearSearchFilters()
        setHasFilter(false)
        setShowRecords(false);
    }

    const updatePageSize = (size) => {
        setPageSize(size, setPageIndex(0))
    }

    const updatePageIndex = (new_index) => {
        if (new_index === 'next') {
            new_index = pageIndex + 1
        } else if (new_index === 'prev') {
            new_index = pageIndex - 1
        }

        if (new_index >= 0 && new_index < pageCount) {
            setPageIndex(new_index)
        }
    }

    const showColumnModal = (display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            let columns = { ...allColumns };
            setModalContent(<SelectColumnsModal
                modalBlur={modalBlur} modalClose={showColumnModal}
                columns={columns} updateColumnDisplay={updateColumnDisplay}
            />)
            displayModal()
            refocusElement('select_columns_modal_dialog_close', 0)
        } else {
            setModalContent("")
            closeModal()
            refocusElement('participant_activity_select_columns_button', 0);
        }
    }

    const updateColumnDisplay = (event, col) => {
        let new_column = allColumns[col]

        if (new_column) {
            new_column.display = !new_column.display
            setAllColumns({
                ...allColumns,
                [col]: new_column
            })
        }
    }

    const exportToCsvHeaders = useCallback(async () => {
        let headers = [];
        let selected_columns = [];

        Object.keys(allColumns).forEach(col => {
            let element = allColumns[col]
            if (element.display) {
                headers.push(element.name)
                selected_columns.push(element.id)
            }
        });
        setCsvData([headers])
        setCsvDataHeaders(selected_columns)
        setDownloadingPageIndex(0)
    }, [allColumns])

    const showDownloadingModal = useCallback((display) => {
        if (display) {
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(<DownloadingModal
                modalBlur={modalBlur} modalClose={showDownloadingModal}
                downloadingInProgress={true}
            />)
            displayModal()
            refocusElement('downloading_modal_dialog_close', 0)
            setDownloadingInProgress(true)
            exportToCsvHeaders()
        } else {
            setDownloadingInProgress(false)
            setDownloadingPageIndex(null)
            setCsvData(null)
            setCsvDataHeaders(null)
            setModalContent("")
            closeModal()
            refocusElement('participant_activity_download_button', 0);
        }
    }, [closeModal, displayModal, exportToCsvHeaders, refocusElement, setModalContent])


    const downloadFile = useCallback(({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
        showDownloadingModal(false)
    }, [showDownloadingModal])

    const showDownloadingError = useCallback(() => {
        setDownloadingInProgress(false)
        setDownloadingPageIndex(null)
        setCsvData(null)
        setCsvDataHeaders(null)

        setModalContent(<DownloadingModal
            modalBlur={modalBlur} modalClose={showDownloadingModal}
            downloadingInProgress={false}
        />)
    }, [setModalContent, showDownloadingModal])

    const escapeCsvField = (field) => {
        if (field === null || field === undefined) {
            return '';
        }
        if (typeof field !== 'string') {
            field = String(field);
        }
        if (field.includes(',') || field.includes('"') || field.includes('\n') || field.includes('\r') || field.startsWith(' ') || field.endsWith(' ')) {
            return `"${field.replace(/"/g, '""')}"`;
        }
        return field;
    }

    const openResendEmailDialog = (email, display) => {
        if (display) {
            setElementToFocus("manage_emails_" + email.id)
            document.getElementById('modal_background').style.paddingTop = `${document.documentElement.scrollTop + 50}px`;
            setModalContent(
                <ResendEmailModal
                    id={email.id}
                    modalBlur={modalBlur}
                    openResendEmailDialog={openResendEmailDialog}
                />
            )
            displayModal()
            refocusElement('resend_email_modal_dialog_close', 0)
        } else {
            setModalContent("")
            closeModal()
            if (document.getElementById(elementToFocus)) {
                this.refocusElement(elementToFocus, 0)
            }
        }
    }

    useEffect(() => {
        async function fetchData() {
            if (downloadingPageIndex != null && csvData) {
                if (downloadingInProgress) {
                    let download_page_size = 500;
                    let body_data = {
                        event_id: event_id,
                        company_id: company_id,
                        email_type: selectedEmailType,
                        coordinator_name: '',
                        coordinator_id: '',
                        email: selectedEmail,
                        student_id: selectedStudentId,
                        order_id: selectedOrderId,
                        dynamics_id: '',
                        school_name: '',
                        start_send_date: selectedStartDate,
                        end_send_date: selectedEndDate,
                        sort_by: sortBy,
                        order_by: orderBy,
                        page_size: pageSize !== 'All' ? parseInt(pageSize) : 'All',
                        page_index: pageIndex,
                    }

                    var formBody = buildForm(body_data)
                    var http_headers = new Headers();
                    http_headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
                    let jwt = await getUserJwt()
                    var bearer = "Bearer " + jwt;
                    http_headers.append("Authorization", bearer);

                    fetch(`${getApiBaseUrl()}/email/searchAllEmails`, {
                        method: 'post',
                        headers: http_headers,
                        body: formBody,
                    })
                        .then((response) => {
                            return response.json()
                        })
                        .then((responseJson) => {
                            if (responseJson.data) {
                                let new_data = csvData
                                responseJson.data.emails.forEach(element => {
                                    let array = [];
                                    csvDataHeaders.forEach(header => {
                                        array.push(escapeCsvField(element[header]))
                                    });

                                    new_data.push(array)
                                });

                                setCsvData(new_data)

                                if (responseJson.data.emails.length < download_page_size) {
                                    let today = new Date();
                                    let formattedDate = formatDate(today);

                                    downloadFile({
                                        data: new_data.map(row => row.join(',')).join('\n'),
                                        fileName: `emails${formattedDate}.csv`,
                                        fileType: 'text/csv',
                                    })
                                } else {
                                    setDownloadingPageIndex(downloadingPageIndex + 1)
                                }
                            } else {
                                showDownloadingError()
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            showDownloadingError()
                        });
                } else {
                    showDownloadingError()
                }
            }
        }
        fetchData()
    }, [downloadingPageIndex, csvData, csvDataHeaders, downloadFile, downloadingInProgress, getUserJwt,
        orderBy, selectedEmailType, selectedEmail, selectedStudentId, selectedOrderId, selectedStartDate, selectedEndDate, showDownloadingError, sortBy,
        company_id, event_id, pageIndex, pageSize
    ])

    useEffect(() => {
        setShowSchoolName(false)
    })

    return (
        <div className="page_container">
            <div className='menu_content'>
                <SchoolSideBar event_id={event_id} company_id={company_id}></SchoolSideBar>
            </div>
            <div className="page_content">
                <h1 className="page_heading">Manage Emails</h1>
                <div className="section_border">
                    <div className='search_section_info_text'>Select your search criteria below.</div>
                    <div className='flex_section search_section_row'>
                        <div className='search_section_item'>
                            <label htmlFor="emails_search_email_type">Email Type</label>
                            <select id="emails_search_email_type" aria-label="Event Type Required"
                                value={selectedEmailType} onChange={e => setSelectedEmailType(e.target.value)}>
                                <option key="email_type_default" value="">All</option>
                                {
                                    emailTypes.map((option, index) => (
                                        <option key={"email_type_id" + option.id} value={option.id}>{option.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="emails_search_email">Email</label>
                            <input type='text' id="emails_search_email" value={selectedEmail} onChange={e => setSelectedEmail(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="emails_search_order_id">Order ID</label>
                            <input type='number' min="0" step="1" id="emails_search_order_id" value={selectedOrderId} onChange={e => setSelectedOrderId(e.target.value)} />
                        </div>
                    </div>
                    <div className='flex_section search_section_row'>
                        <div className='search_section_item'>
                            <label htmlFor="emails_search_student_id">Participant ID</label>
                            <input type='number' min="0" step="1" id="emails_search_student_id" value={selectedStudentId} onChange={e => setSelectedStudentId(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="emails_search_start_date">Start Date</label>
                            <input type='date' id="emails_search_start_date" value={selectedStartDate} min="2000-01-01" max="9999-12-31" onChange={e => setSelectedStartDate(e.target.value)} />
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="emails_search_end_date">End Date</label>
                            <input type='date' id="emails_search_end_date" value={selectedEndDate} min="2000-01-01" max="9999-12-31" onChange={e => setSelectedEndDate(e.target.value)} />
                        </div>
                    </div>
                    <div className='flex_section search_section_row'>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_sort_by">Sort By</label>
                            <select id="schools_search_sort_by" value={displaySortBy} onChange={e => setDisplaySortBy(e.target.value)}>
                                <option key="email_type" value="email_type" className={allColumns.email_type.display ? "" : "hidden"}>Email Type</option>
                                <option key="name" value="name" className={allColumns.name.display ? "" : "hidden"}>Name</option>
                                <option key="email" value="email" className={allColumns.email.display ? "" : "hidden"}>Email</option>
                                <option key="status" value="status" className={allColumns.status.display ? "" : "hidden"}>Status</option>
                                <option key="sent_date" value="sent_date" className={allColumns.sent_date.display ? "" : "hidden"}>Sent Date</option>
                                <option key="opened" value="opened" className={allColumns.opened.display ? "" : "hidden"}>Opened</option>
                                <option key="click_through" value="click_through" className={allColumns.click_through.display ? "" : "hidden"}>Click Through</option>
                            </select>
                        </div>
                        <div className='search_section_item'>
                            <label htmlFor="schools_search_order_by">Order By</label>
                            <select id="schools_search_order_by" value={displayOrderBy} onChange={e => setDisplayOrderBy(e.target.value)}>
                                <option key="ASC" value="ASC">Ascending</option>
                                <option key="DESC" value="DESC">Descending</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex_section search_section_row search_section_buttons_row'>
                        <div className="search_section_error_text"></div>
                        <div className='search_section_search_buttons'>
                            <button className={"blue_text_button " + (showRecords && hasFilter ? 'displayBlock' : 'displayHidden')} onClick={() => clearSearch()}>Clear Search</button>
                            <button className="red_button" onClick={handleSearch}>Search</button>
                        </div>
                    </div>
                </div>
                {showRecords && (
                    <div>
                        <div className='flex_section search_section_table_heading_row'>
                            <div className='small_heading search_section_table_heading_label'>Email Search Results</div>
                            <div>
                                <div className='search_section_table_buttons'>
                                    <button id="participant_activity_select_columns_button" tabIndex="0" data-modal="true" className="red_outline_button" onClick={() => showColumnModal(true)}>Select Columns</button>
                                    <button id="participant_activity_download_button" className="red_button" onClick={(event) => showDownloadingModal(true)}>Download</button>
                                </div>

                                <TablePageSizeOptions page_options={[10, 25, 50, 'All']} page_value={pageSize} page_action={updatePageSize} />
                            </div>
                        </div>
                        <div className="table_container">
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            Object.entries(allColumns).map((col, index) => (
                                                <SortTableHeader key={index} column_label={col[1].name} column_id={col[1].id} sort_by={sortBy} order_by={orderBy} action={clickSortColumn} enterAction={handleEnterPressed} hidden={!col[1].display} />
                                            ))
                                        }
                                        <th>
                                            <div className="table_column_header_button">
                                                <div>Action</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        records.length > 0 ?
                                            records.map((email, index) => (
                                                <tr id={`email${index}`} key={index}>
                                                    {
                                                        Object.entries(allColumns).map((col, index) => (
                                                            <DataTableCell key={col[1].id} name={col[1].id} row={email} columns={allColumns}></DataTableCell>
                                                        ))
                                                    }
                                                    <td>
                                                        <button id={"resend_emails_" + email.id}
                                                            className='blue_text_button' aria-label={"Resend email"}
                                                            onClick={() => openResendEmailDialog(email, true)}>Resend</button>
                                                    </td>
                                                </tr>
                                            ))
                                            : <tr><td className='report_no_results' colSpan={100}>{allowNoResults ? 'No Results Found' : ''} </td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        {
                            pageCount > 1 && <SimplePagination pageCount={pageCount} pageIndex={pageIndex} clickAction={updatePageIndex}></SimplePagination>
                        }
                    </div>
                )}
            </div>
        </div>
    )

    function DataTableCell(props) {
        if (props.columns[props.name].display) {
            return (
                <td>{props.row[props.name]}</td>
            )
        } else {
            return <></>
        }
    }
};

export default SchoolEmails;